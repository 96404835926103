// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signOut
} from "firebase/auth";

import { FIREBASE_CONFIG, SCOPES } from "@/config";

const provider = new GoogleAuthProvider();
provider.addScope(SCOPES.CALENDAR);

// Initialize Firebase
const app = initializeApp(FIREBASE_CONFIG);
const auth = getAuth(app);

export const googleOAuth = () => {
  return new Promise((resolve, reject) => {
    signInWithPopup(auth, provider)
      .then(result => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const { user, _tokenResponse } = result;
        setGapiToken(token);
        resolve({ token, user, resp: _tokenResponse });
      })
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        reject({
          errorCode,
          errorMessage,
          email,
          credential
        });
      });
  });
};

export const signUserOut = () => {
  return new Promise((resolve, reject) => {
    signOut(auth)
      .then(() => {
        resolve();
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const setGapiToken = token => {
  window.gapi.client.setToken({ access_token: token });
};
