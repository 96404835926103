<template>
  <div>
    <v-card max-width="500px" class="mx-auto mt-5">
      <v-card-title>User Info</v-card-title>
      <v-card-text>
        <v-form
          v-on:submit.prevent="updateUser"
          class="pa-3 text-sm-center"
          v-model="isValid"
        >
          <v-text-field
            label="First Name"
            v-model="form.first_name"
            color="primary"
            :rules="[rules.requiredRule]"
            required
          ></v-text-field>
          <v-text-field
            label="Last Name"
            v-model="form.last_name"
            color="primary"
            :rules="[rules.requiredRule]"
            required
          ></v-text-field>

          <v-text-field
            label="Email"
            v-model="form.email"
            color="primary"
            :rules="[rules.requiredRule, rules.emailRule]"
            required
          ></v-text-field>

          <v-text-field
            v-model="form.password"
            :rules="[rules.requiredRule]"
            name="input-10-asdasd-1"
            label="Enter Password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>

          <v-btn :loading="loading" class="secondary" type="submit"
            >Update Profile</v-btn
          >
        </v-form>

        <v-divider></v-divider>
        <v-btn
          @click="connectWithGoogle"
          :disabled="isConnected"
          class="mt-5 mr-5"
          color="primary"
          :loading="loading"
          >Reconnect With Google</v-btn
        >
        <v-btn
          @click="logout"
          :disabled="!isConnected"
          class="mt-5"
          color="error"
          :loading="loading"
          >Disconnect Google Account</v-btn
        >

        <p class="mt-5" v-if="isConnected">
          Connected As:
          <span class="bold">{{ user.google_authentication_email }}</span>
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { signUserOut, googleOAuth } from "@/firebase";
import { mapMutations, mapState } from "vuex";
import { requiredRule, emailRule } from "@/helpers/form-rules";
export default {
  name: "user-profile",
  data() {
    return {
      loading: false,
      isValid: false,
      showPassword: false,
      form: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
      },
      rules: { requiredRule, emailRule },
    };
  },
  computed: {
    ...mapState(["user"]),
    isConnected() {
      const { google_access_token } = this.user;
      return !!google_access_token;
    },
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    async logout() {
      try {
        this.loading = true;
        await signUserOut();
        const updatedUser = {
          ...this.user,
          google_access_token: "",
        };
        await this.axios.put(`user/${this.user._id}`, updatedUser);
        this.SET_USER(updatedUser);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async connectWithGoogle() {
      try {
        const { token, user } = await googleOAuth();
        const updatedUser = {
          ...this.user,
          google_access_token: token,
          google_authentication_email: user.email,
        };
        await this.axios.put(`user/${this.user._id}`, updatedUser);
        this.SET_USER(updatedUser);
      } catch (error) {
        console.log(error);
      }
    },
    async updateUser() {
      try {
        if (!this.isValid) return;
        this.loading = true;
        const userDetails = { ...this.form };
        if (!userDetails.password) {
          delete userDetails.password;
        }

        const updatedUser = {
          ...this.user,
          ...userDetails,
        };

        await this.axios.put(`user/${this.user._id}`, updatedUser);
        this.SET_USER(updatedUser);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    for (const key in this.form) {
      if (Object.hasOwnProperty.call(this.form, key)) {
        this.form[key] = this.user[key];
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
